var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mb-3",attrs:{"align":"center"}},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{directives:[{name:"debounce",rawName:"v-debounce:500ms",value:(_vm.getItems),expression:"getItems",arg:"500ms"}],attrs:{"label":"ابحث هنا","color":"indigo","hide-details":"","prepend-inner-icon":"search","outlined":"","dense":"","filled":""},model:{value:(_vm.$global.state.filter.search),callback:function ($$v) {_vm.$set(_vm.$global.state.filter, "search", $$v)},expression:"$global.state.filter.search"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"clearable":"","item-text":"name","item-value":"id","items":_vm.$global.state.provinces,"color":"indigo","label":"المحافظة","loading":_vm.$global.state.loading,"no-data-text":"لا توجد بيانات","hide-details":"","prepend-inner-icon":"place","outlined":"","dense":"","filled":""},on:{"input":function($event){return _vm.getItems()}},model:{value:(_vm.$global.state.provinceId),callback:function ($$v) {_vm.$set(_vm.$global.state, "provinceId", $$v)},expression:"$global.state.provinceId"}})],1)],1),_c('v-card',{attrs:{"id":"card"}},[_c('v-data-table',{staticClass:"mytable",attrs:{"items-per-page":_vm.$global.state.filter.pageSize,"items":_vm.$global.state.clients,"loading":_vm.$global.state.loading,"headers":_vm.headers,"search":_vm.$global.state.filter.search,"hide-default-footer":"","loading-text":"جاري تحميل البيانات","no-data-text":"لا توجد بيانات","id":_vm.$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$global.state.clients.indexOf(item) + 1))])]}},{key:"item.phoneNumber",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"dir":"ltr"}},[_vm._v(_vm._s(_vm._f("phone")(item.phoneNumber)))])]}},{key:"item.isBlock",fn:function(ref){
var item = ref.item;
return [(item.isBlock)?_c('v-chip',{attrs:{"small":"","dark":"","color":"red lighten-1"}},[_vm._v("محضور")]):_c('v-chip',{attrs:{"dark":"","small":"","color":"green lighten-1"}},[_vm._v("فعال")])]}},{key:"item.rating",fn:function(ref){
var item = ref.item;
return [_c('v-rating',{attrs:{"color":"primary","background-color":"grey lighten-1","length":"5","dense":"","readonly":"","small":"","value":item.rating}},[_vm._v(_vm._s())])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(new Date(item.created).toISOString().substring(0,10)))])]}}])})],1),_c('div',{staticClass:"d-flex justify-space-between py-3"},[_c('v-spacer'),_c('Pagination')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }